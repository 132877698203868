import { ModalHeader, Modal, ModalBody, ModalFooter, Button } from "reactstrap";

const DonationSuccessModal = ({ isOpen, toggle, name, amount }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader toggle={toggle} className="text-success">
        Donation Successful
      </ModalHeader>
      <ModalBody className="text-center">
        {/* <FaCheckCircle size={60} color="green" className="mb-3" /> */}
        <h4>Thank You for Your Donation!</h4>
        <p className="mt-3">
          Thanks For Donating <b className="text-success">₹{amount}</b> for{" "}
          <b>{name}</b>
        </p>
      </ModalBody>
    </Modal>
  );
};

export default DonationSuccessModal;
