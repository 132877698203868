import React from "react";

const Footer = () => {
  return (
    <div class="footer" id="contact">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-md-6">
            <div class="footer-contact">
              <h2>Our Head Office</h2>
              <p>
                <i class="fa fa-map-marker-alt"></i>
                Ground 6/322 Chirakkal Kattampally Mayyil Kololam Road N/R
                Mullakkodi Cooperative Rural Bank, Aram Peedika, Narath, Kannur,
                Kerala, 670601
              </p>
              <p>
                <i class="fa fa-phone-alt"></i>+917907088056
              </p>
              <p>
                <i class="fa fa-envelope"></i>info@dayalifeline.com
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="footer-link">
              <h2>Popular Links</h2>
              <a href="#about">About Us</a>
              <a href="#contact">Contact Us</a>
              <a href="#our-app">Our App</a>
              <a href="#service">What We Do</a>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="footer-link">
              <h2>Useful Links</h2>
              <a href="/terms&conditions.html">Terms & Conditions</a>
              <a href="/privacy-policy.html">Privacy policy</a>
              <a href="/cancellation-refund-policies.html">
                Cancellation / Refund Policies
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="container copyright">
        <div class="row">
          <div class="col-md-6">
            <p>
              &copy; <a href="#/">DayaLifeline</a>, All Right Reserved.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
