const Modal = ({ show, onClose, children }) => {
  if (!show) return null;

  return (
    <div className="custom-modal" onClick={onClose}>
      <div
        className="custom-modal-content"
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </div>
  );
};

export default Modal;
