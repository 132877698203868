import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import Protected from "./protected";
import { isAuthenticated } from "./helpers";
import Home from "../pages/Home";
import Login from "../pages/Login";
import About from "../pages/About";

// Protected
import Campaigns from "../pages/Campaigns";
import Layout from "../components/common/Layout";
import TermsConditions from "../pages/Terms&Conditions";
import PrivacyPolicy from "../pages/Privacy&Policy";
import CancellationRefundPolicies from "../pages/CancellationRefundPolicies";
import CampaignDetail from "../pages/CampaignDetails";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getProfileDetails } from "../GlobalRedux/Features/auth/authSlice";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>
      <Route index element={<Home />} />
      <Route
        path="login"
        element={<Login />}
        loader={async () => await isAuthenticated()}
      />
      <Route path="about" element={<About />} />
      <Route path="terms&conditions.html" element={<TermsConditions />} />
      <Route path="privacy-policy.html" element={<PrivacyPolicy />} />
      <Route
        path="cancellation-refund-policies.html"
        element={<CancellationRefundPolicies />}
      />
      <Route path="campaigns" element={<Campaigns />} />
      <Route path="campaigns/:id" element={<CampaignDetail />} />

      {/* Protected Routes Here */}
      <Route element={<Protected />}>
        <Route element={<Campaigns />} />
        {/* All other routes that you want to protect will go inside here */}
      </Route>

      <Route path="*" element={<h1>Page not found</h1>} />
    </Route>
  )
);

const AppRouter = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProfileDetails());
  }, []);

  return <RouterProvider router={router} />;
};

export default AppRouter;
