import React from "react";

const TermsConditions = () => {
  return (
    <div class="container" style={{ paddingTop: 70 }}>
      <h2>1. Introduction</h2>
      <p>
        Welcome to DayaLifeline! By using our app, you agree to these Terms and
        Conditions. Please read them carefully.
      </p>

      <h2>2. Services</h2>
      <p>
        DayaLifeline provides a platform for users to raise funds and make
        donations. We facilitate direct bank transfers from donors to recipients
        and earn a commission on successful campaigns.
      </p>

      <h2>3. User Accounts</h2>
      <ul>
        <li>You must create an account to use our services.</li>
        <li>Keep your account information secure and confidential.</li>
        <li>You are responsible for all activities under your account.</li>
      </ul>

      <h2>4. Donations and Campaigns</h2>
      <ul>
        <li>All donations are voluntary and non-refundable.</li>
        <li>Campaign creators must provide accurate information.</li>
        <li>We reserve the right to review and approve campaigns.</li>
      </ul>

      <h2>5. Commission and Fees</h2>
      <p>
        We earn a commission after a campaign is successfully completed. Details
        of our commission structure are available on our app.
      </p>

      <h2>6. Prohibited Activities</h2>
      <ul>
        <li>Do not use our app for illegal or fraudulent activities.</li>
        <li>Do not impersonate others or provide false information.</li>
      </ul>

      <h2>7. Termination</h2>
      <p>
        We reserve the right to suspend or terminate accounts that violate our
        terms or engage in prohibited activities.
      </p>

      <h2>8. Limitation of Liability</h2>
      <p>
        We are not liable for any direct, indirect, incidental, or consequential
        damages arising from the use of our app.
      </p>

      <h2>9. Changes to Terms</h2>
      <p>
        We may update these Terms and Conditions from time to time. We will
        notify you of any changes through the app.
      </p>

      <h2>10. Contact Us</h2>
      <p>
        For questions or concerns, please contact us at{" "}
        <a href="mailto:info@dayalifeline.com">info@dayalifeline.com</a>.
      </p>
    </div>
  );
};

export default TermsConditions;
