import fetchApi from "./index";

const campaignService = {
  getCampaigns: async () => {
    const data = await fetchApi({
      url: `/get-public-campaign`,
      method: "get",
    });

    return data.data;
  },

  getCampaignById: async (id) => {
    const data = await fetchApi({
      url: `/get-public-campaign-id/${id}`,
      method: "get",
    });
    return data?.campaign;
  },

  getBanners: async () => {
    const data = await fetchApi({
      url: `/get-public-banner`,
      method: "get",
    });

    return data;
  },
  createPaymentOrder: async (sendingValue) => {
    const data = await fetchApi({
      url: `/create-payment-order`,
      method: "post",
      data: sendingValue,
    });
    return data;
  },
  verifyPaymentOrder: async (sendingValue) => {
    const data = await fetchApi({
      url: `/verify-payment`,
      method: "post",
      data: sendingValue,
    });

    return data;
  },
};

export default campaignService;
