import React, { useEffect } from "react";
import AppRouter from "./AppRouter";
import Provider from "./GlobalRedux/Provider";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function App() {
  return (
    <Provider>
      <ToastContainer />

      <AppRouter />
    </Provider>
  );
}
