// import React, { useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   getCampaignBanners,
//   getCampaigns,
// } from "../../GlobalRedux/Features/campaign/campaignSlice";
// import moment from "moment";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

// // Slick slider settings
// const sliderSettings = {
//   dots: true,
//   infinite: true,
//   speed: 500,
//   slidesToShow: 1,
//   slidesToScroll: 1,
//   autoplay: true,
//   autoplaySpeed: 3000,
// };

// const Campaigns = () => {
//   const dispatch = useDispatch();

//   const { campaigns, banners } = useSelector((state) => state.campaign);

//   useEffect(() => {
//     dispatch(getCampaigns());
//     dispatch(getCampaignBanners());
//   }, [dispatch]);

//   // Function to calculate the progress percentage
//   const calculateProgress = (total, needed) => {
//     return ((total / needed) * 100).toFixed(2);
//   };

//   // Function to format created_at to relative time
//   const formatCreatedAt = (date) => {
//     return moment(date).fromNow();
//   };

//   return (
//     <div className="container" style={{ marginTop: 80 }}>
//       {/* Banner Slider */}
//       {banners?.data?.length > 0 && (
//         <Slider {...sliderSettings} className="mb-4">
//           {banners.data.map((banner) => (
//             <div key={banner.id}>
//               <img
//                 src={banner.image}
//                 alt={banner.title}
//                 style={{ width: "100%", height: "400px", objectFit: "cover" }}
//               />
//             </div>
//           ))}
//         </Slider>
//       )}

//       <h2 className="mb-4 text-center">Campaign Listing</h2>
//       <div className="row mb-3">
//         <div className="col-12">
//           <input
//             type="text"
//             className="form-control"
//             placeholder="Search campaigns by title or location..."
//             // value={searchTerm}
//             // onChange={(e) => setSearchTerm(e.target.value)}
//           />
//         </div>
//       </div>

//       <div className="row">
//         {campaigns?.data?.length > 0 ? (
//           campaigns.data.map((campaign) => (
//             <div className="col-lg-4 col-md-6 mb-4" key={campaign.id}>
//               <div
//                 className="card h-100 shadow-sm position-relative cursor-pointer"
//                 role="button"
//               >
//                 <div className="position-relative">
//                   {campaign.is_emergency && (
//                     <span className="badge bg-danger position-absolute top-0 end-0 m-2">
//                       Emergency
//                     </span>
//                   )}
//                   <img
//                     src={campaign.image[0].url}
//                     className="card-img-top"
//                     alt={campaign.title}
//                     style={{ height: 250, width: "100%", objectFit: "cover" }}
//                   />
//                   <div
//                     className="position-absolute bottom-0 end-0 p-2"
//                     style={{
//                       backgroundColor: "white",
//                       boxShadow: "0 -1px 5px rgba(0,0,0,0.3)",
//                     }}
//                   >
//                     {campaign.sponsor_logo && (
//                       <div className="d-flex align-items-center">
//                         <img
//                           src={campaign.sponsor_logo}
//                           alt={campaign.sponsor_name}
//                           className="img-fluid"
//                           style={{ height: "30px", marginRight: "8px" }}
//                         />
//                         {campaign.sponsor_name && (
//                           <span>{campaign.sponsor_name}</span>
//                         )}
//                       </div>
//                     )}
//                   </div>
//                 </div>

//                 <div className="card-body">
//                   <h5 className="card-title">{campaign.title}</h5>
//                   <p className="card-text">
//                     <strong>Location:</strong> {campaign.location}
//                   </p>
//                   <p className="card-text">
//                     <strong>Amount Needed:</strong> ₹{campaign.amount_needed}
//                   </p>
//                   <p className="card-text">
//                     <strong>Currently Raised:</strong> ₹{campaign.total_amount}
//                   </p>
//                   <div className="progress mb-3">
//                     <div
//                       className="progress-bar bg-success"
//                       role="progressbar"
//                       style={{
//                         width: `${calculateProgress(
//                           campaign.total_amount,
//                           campaign.amount_needed
//                         )}%`,
//                       }}
//                       aria-valuenow={campaign.total_amount}
//                       aria-valuemin="0"
//                       aria-valuemax={campaign.amount_needed}
//                     >
//                       {calculateProgress(
//                         campaign.total_amount,
//                         campaign.amount_needed
//                       )}
//                       %
//                     </div>
//                   </div>
//                 </div>
//                 <div className="card-footer d-flex justify-content-between">
//                   <small className="text-muted">
//                     By {campaign.owner_name} -{" "}
//                     {formatCreatedAt(campaign.created_at)}
//                   </small>
//                 </div>
//               </div>
//             </div>
//           ))
//         ) : (
//           <div className="col-12">
//             <p className="text-center">No campaigns found.</p>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Campaigns;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCampaignBanners,
  getCampaigns,
} from "../../GlobalRedux/Features/campaign/campaignSlice";
import moment from "moment";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";

// Slick slider settings
const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
};

const Campaigns = () => {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const { campaigns, banners } = useSelector((state) => state.campaign);

  useEffect(() => {
    dispatch(getCampaigns());
    dispatch(getCampaignBanners());
  }, [dispatch]);

  // Function to calculate the progress percentage
  const calculateProgress = (total, needed) => {
    return ((total / needed) * 100).toFixed(2);
  };

  // Function to format created_at to relative time
  const formatCreatedAt = (date) => {
    return moment(date).fromNow();
  };

  // Filter campaigns based on search term
  const filteredCampaigns = campaigns?.data?.filter(
    (campaign) =>
      campaign.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      campaign.location.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="container" style={{ marginTop: 80 }}>
      {/* Banner Slider */}
      {banners?.data?.length > 0 && (
        <Slider {...sliderSettings} className="mb-4">
          {banners.data.map((banner) => (
            <div key={banner.id}>
              <img
                src={banner.image}
                alt={banner.title}
                style={{ width: "100%", height: "400px", objectFit: "cover" }}
              />
            </div>
          ))}
        </Slider>
      )}

      <h2 className="mb-4 text-center">Campaign Listing</h2>
      <div className="row mb-3">
        <div className="col-12">
          <input
            type="text"
            className="form-control"
            placeholder="Search Campaigns"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>

      <div className="row">
        {filteredCampaigns?.length > 0 ? (
          filteredCampaigns.map((campaign) => (
            <div className="col-lg-4 col-md-6 mb-4" key={campaign.id}>
              <div
                className="card h-100 shadow-sm position-relative cursor-pointer"
                role="button"
                onClick={() => navigate(`/campaigns/${campaign.id}`)}
              >
                <div className="position-relative">
                  {campaign.is_emergency && (
                    <span className="badge bg-danger position-absolute top-0 end-0 m-2">
                      Emergency
                    </span>
                  )}
                  <img
                    src={campaign.image[0].url}
                    className="card-img-top"
                    alt={campaign.title}
                    style={{ height: 250, width: "100%", objectFit: "cover" }}
                  />
                  <div
                    className="position-absolute bottom-0 end-0 p-2"
                    style={{
                      backgroundColor: "white",
                      boxShadow: "0 -1px 5px rgba(0,0,0,0.3)",
                    }}
                  >
                    {campaign.sponsor_logo && (
                      <div className="d-flex align-items-center">
                        <img
                          src={campaign.sponsor_logo}
                          alt={campaign.sponsor_name}
                          className="img-fluid"
                          style={{ height: "30px", marginRight: "8px" }}
                        />
                        {campaign.sponsor_name && (
                          <span>{campaign.sponsor_name}</span>
                        )}
                      </div>
                    )}
                  </div>
                </div>

                <div className="card-body">
                  <h5 className="card-title">{campaign.title}</h5>
                  <p className="card-text">
                    <strong>Location:</strong> {campaign.location}
                  </p>
                  <p className="card-text">
                    <strong>Amount Needed:</strong> ₹{campaign.amount_needed}
                  </p>
                  <p className="card-text">
                    <strong>Currently Raised:</strong> ₹{campaign.total_amount}
                  </p>
                  <div className="progress mb-3">
                    <div
                      className="progress-bar bg-success"
                      role="progressbar"
                      style={{
                        width: `${calculateProgress(
                          campaign.total_amount,
                          campaign.amount_needed
                        )}%`,
                      }}
                      aria-valuenow={campaign.total_amount}
                      aria-valuemin="0"
                      aria-valuemax={campaign.amount_needed}
                    >
                      {calculateProgress(
                        campaign.total_amount,
                        campaign.amount_needed
                      )}
                      %
                    </div>
                  </div>
                </div>
                <div className="card-footer d-flex justify-content-between">
                  <small className="text-muted">
                    By {campaign.owner_name} -{" "}
                    {formatCreatedAt(campaign.created_at)}
                  </small>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="col-12">
            <p className="text-center">No campaigns found.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Campaigns;
