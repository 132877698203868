import React from "react";

const CancellationRefundPolicies = () => {
  return (
    <div class="container" style={{ paddingTop: 70 }}>
      <h2>1. Donations and Refunds</h2>
      <p>
        All donations made through DayaLifeline are voluntary and
        non-refundable. Please ensure that you have thoroughly reviewed the
        campaign before making a donation.
      </p>

      <h2>2. Campaign Cancellations</h2>
      <p>
        If a campaign is canceled before its completion, no refunds will be
        provided to the donors. The remaining funds will be transferred to the
        campaign creator, minus our commission.
      </p>

      <h2>3. Fund Disbursement</h2>
      <p>
        Once a campaign reaches its end or goal, funds are automatically
        transferred to the recipient’s bank account. This process is
        irreversible, and no refunds can be processed after disbursement.
      </p>

      <h2>4. Commission Policy</h2>
      <p>
        DayaLifeline retains a commission on all successfully completed
        campaigns. This commission is non-refundable under any circumstances.
      </p>

      <h2>5. Dispute Resolution</h2>
      <p>
        If there is a dispute regarding a donation or campaign, please contact
        our support team at{" "}
        <a href="mailto:info@dayalifeline.com">info@dayalifeline.com</a>. We
        will review the case and take appropriate action.
      </p>

      <h2>6. Contact Information</h2>
      <p>
        For any questions or concerns regarding our cancellation and refund
        policies, please reach out to us at{" "}
        <a href="mailto:info@dayalifeline.com">info@dayalifeline.com</a>.
      </p>
    </div>
  );
};

export default CancellationRefundPolicies;
