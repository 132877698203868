// import React, { useState } from "react";
// import { useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import {
//   otpVerification,
//   phoneNumberLogin,
// } from "../../GlobalRedux/Features/auth/authSlice";

// const PhoneNumberLogin = () => {
//   const [phoneNumber, setPhoneNumber] = useState("");
//   const [otp, setOtp] = useState("");
//   const [errorMessage, setErrorMessage] = useState("");
//   const [otpSent, setOtpSent] = useState(false);
//   const [otpVerified, setOtpVerified] = useState(false);

//   const router = useNavigate();

//   const dispatch = useDispatch();

//   const handleSendOTP = async (e) => {
//     e.preventDefault();
//     const phoneRegex = /^[0-9]{10}$/;

//     if (!phoneRegex.test(phoneNumber)) {
//       setErrorMessage("Please enter a valid 10-digit phone number.");
//       return;
//     }

//     const sendingData = { phone_number: "+91" + phoneNumber };

//     await dispatch(phoneNumberLogin({ sendingData }));
//     setErrorMessage("");
//     setOtpSent(true);
//     console.log(`OTP sent to ${phoneNumber}`);
//   };

//   const handleVerifyOTP = async (e) => {
//     e.preventDefault();
//     const sendingData = { phone_number: "+91" + phoneNumber, otp: otp };

//     await dispatch(otpVerification({ sendingData, router }));

//     console.log("OTP Verified");

//     setOtpVerified(true);
//     setErrorMessage("");
//   };

//   return (
//     <div className="d-flex align-items-center justify-content-center min-vh-100 bg-light">
//       <div className="card shadow-lg" style={{ width: "400px" }}>
//         <div className="card-body p-4">
//           {!otpSent ? (
//             <>
//               <h3 className="text-center mb-4">Login with Phone Number</h3>

//               <form onSubmit={handleSendOTP}>
//                 <div className="form-group mb-3">
//                   <label htmlFor="phoneNumber" className="form-label">
//                     Phone Number
//                   </label>
//                   <input
//                     type="text"
//                     id="phoneNumber"
//                     className={`form-control ${
//                       errorMessage ? "is-invalid" : ""
//                     }`}
//                     placeholder="Enter your phone number"
//                     value={phoneNumber}
//                     onChange={(e) => setPhoneNumber(e.target.value)}
//                     required
//                   />
//                   {errorMessage && (
//                     <div className="invalid-feedback">{errorMessage}</div>
//                   )}
//                 </div>

//                 <button type="submit" className="btn btn-primary btn-block">
//                   Send OTP
//                 </button>
//               </form>

//               <div className="mt-3 text-center">
//                 <small className="text-muted">
//                   We will never share your phone number with anyone.
//                 </small>
//               </div>
//             </>
//           ) : !otpVerified ? (
//             <>
//               <h3 className="text-center mb-4">Verify OTP</h3>

//               <form onSubmit={handleVerifyOTP}>
//                 <div className="form-group mb-3">
//                   <label htmlFor="otp" className="form-label">
//                     Enter OTP
//                   </label>
//                   <input
//                     type="text"
//                     id="otp"
//                     className={`form-control ${
//                       errorMessage ? "is-invalid" : ""
//                     }`}
//                     placeholder="Enter OTP"
//                     value={otp}
//                     onChange={(e) => setOtp(e.target.value)}
//                     required
//                   />
//                   {errorMessage && (
//                     <div className="invalid-feedback">{errorMessage}</div>
//                   )}
//                 </div>

//                 <button type="submit" className="btn btn-success btn-block">
//                   Verify OTP
//                 </button>
//               </form>

//               <div className="mt-3 text-center">
//                 <small className="text-muted">
//                   Didn’t receive OTP?{" "}
//                   <button className="btn btn-link p-0" onClick={handleSendOTP}>
//                     Resend
//                   </button>
//                 </small>
//               </div>
//             </>
//           ) : (
//             <div className="text-center">
//               <h3 className="mb-4">OTP Verified!</h3>
//               <p>Welcome! You have successfully logged in.</p>
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default PhoneNumberLogin;
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  otpVerification,
  phoneNumberLogin,
} from "../../GlobalRedux/Features/auth/authSlice";
import {
  Card,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Container,
  Row,
  Col,
} from "reactstrap";

const PhoneNumberLogin = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);

  const router = useNavigate();
  const dispatch = useDispatch();

  const handleSendOTP = async (e) => {
    e.preventDefault();
    const phoneRegex = /^[0-9]{10}$/;

    if (!phoneRegex.test(phoneNumber)) {
      setErrorMessage("Please enter a valid 10-digit phone number.");
      return;
    }

    const sendingData = { phone_number: "+91" + phoneNumber };

    await dispatch(phoneNumberLogin({ sendingData, setOtpSent }));
    setErrorMessage("");
  };

  const handleVerifyOTP = async (e) => {
    e.preventDefault();
    const sendingData = { phone_number: "+91" + phoneNumber, otp: otp };

    await dispatch(otpVerification({ sendingData, router, setOtpVerified }));

    setErrorMessage("");
  };

  return (
    <Container
      fluid
      className="d-flex align-items-center justify-content-center min-vh-100 bg-light"
    >
      <Row className="justify-content-center w-100">
        <Col xs={12} md={6}>
          <Card className="shadow-lg mx-auto">
            <CardBody className="p-4">
              {!otpSent ? (
                <>
                  <h3 className="text-center mb-4">Login with Phone Number</h3>

                  <Form onSubmit={handleSendOTP}>
                    <FormGroup>
                      <Label for="phoneNumber">Phone Number</Label>
                      <Input
                        type="text"
                        id="phoneNumber"
                        className={errorMessage ? "is-invalid" : ""}
                        placeholder="Enter your phone number"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        required
                      />
                      {errorMessage && (
                        <FormFeedback>{errorMessage}</FormFeedback>
                      )}
                    </FormGroup>

                    <Button type="submit" color="primary" block>
                      Send OTP
                    </Button>
                  </Form>

                  <div className="mt-3 text-center">
                    <small className="text-muted">
                      We will never share your phone number with anyone.
                    </small>
                  </div>
                </>
              ) : !otpVerified ? (
                <>
                  <h3 className="text-center mb-4">Verify OTP</h3>

                  <Form onSubmit={handleVerifyOTP}>
                    <FormGroup>
                      <Label for="otp">Enter OTP</Label>
                      <Input
                        type="text"
                        id="otp"
                        className={errorMessage ? "is-invalid" : ""}
                        placeholder="Enter OTP"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        required
                      />
                      {errorMessage && (
                        <FormFeedback>{errorMessage}</FormFeedback>
                      )}
                    </FormGroup>

                    <Button type="submit" color="success" block>
                      Verify OTP
                    </Button>
                  </Form>

                  <div className="mt-3 text-center">
                    <small className="text-muted">
                      Didn’t receive OTP?{" "}
                      <Button
                        color="link"
                        className="p-0"
                        onClick={handleSendOTP}
                      >
                        Resend
                      </Button>
                    </small>
                  </div>
                </>
              ) : (
                <div className="text-center">
                  <h3 className="mb-4">OTP Verified!</h3>
                  <p>Welcome! You have successfully logged in.</p>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default PhoneNumberLogin;
