// import React, { useState } from "react";
// import { Link, useLocation } from "react-router-dom";
// import dayaLogo from "../../../img/daya-logo.png";
// import {
//   Collapse,
//   Navbar,
//   NavbarToggler,
//   Nav,
//   NavItem,
//   NavLink,
// } from "reactstrap";
// import { useDispatch, useSelector } from "react-redux";
// import { setLogout } from "../../../GlobalRedux/Features/auth/authSlice";

// const Header = () => {
//   const location = useLocation();
//   const [isOpen, setIsOpen] = useState(false);

//   const toggleNavbar = () => setIsOpen(!isOpen);

//   const dispatch = useDispatch();

//   const { user } = useSelector((state) => state.auth);

//   return (
//     <Navbar
//       color="dark"
//       dark
//       expand="lg"
//       className="navbar navbar-expand-lg sticky-top"
//     >
//       <div className="container-fluid d-flex align-items-start justify-content-between">
//         <Link to="/" className="navbar-brand">
//           <img
//             src={dayaLogo}
//             alt="logo"
//             style={{
//               height: "120px", // Adjust the logo height to avoid overlapping
//               maxWidth: "150px", // Set a max width to control logo size
//               objectFit: "contain",
//             }}
//             className="mr-3" // Add some margin to the right of the logo
//           />
//         </Link>

//         <Collapse isOpen={isOpen} navbar id="navbarCollapse">
//           <Nav className="ml-auto align-items-center" navbar>
//             <NavItem className="mx-2">
//               <NavLink
//                 tag={Link}
//                 to="/"
//                 className={location.pathname === "/" ? "active" : ""}
//               >
//                 Home
//               </NavLink>
//             </NavItem>
//             <NavItem className="mx-2">
//               <NavLink
//                 tag={Link}
//                 to="/campaigns"
//                 className={location.pathname === "/campaigns" ? "active" : ""}
//               >
//                 Campaigns
//               </NavLink>
//             </NavItem>
//             <NavItem className="mx-2">
//               <NavLink
//                 href="/#about"
//                 className={location.hash === "#about" ? "active" : ""}
//               >
//                 About
//               </NavLink>
//             </NavItem>
//             <NavItem className="mx-2">
//               <NavLink
//                 href="/#service"
//                 className={location.hash === "#service" ? "active" : ""}
//               >
//                 What We Do
//               </NavLink>
//             </NavItem>
//             <NavItem className="mx-2">
//               <NavLink
//                 href="/#our-app"
//                 className={location.hash === "#our-app" ? "active" : ""}
//               >
//                 Our App
//               </NavLink>
//             </NavItem>
//             <NavItem className="mx-2">
//               <NavLink
//                 tag={Link}
//                 to="/contact"
//                 className={location.pathname === "/contact" ? "active" : ""}
//               >
//                 Contact
//               </NavLink>
//             </NavItem>
//           </Nav>
//         </Collapse>
//         <NavbarToggler onClick={toggleNavbar} />
//       </div>
//     </Navbar>
//   );
// };

// export default Header;

import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import dayaLogo from "../../../img/daya-logo.png";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { setLogout } from "../../../GlobalRedux/Features/auth/authSlice";

const Header = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleNavbar = () => setIsOpen(!isOpen);
  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const handleLogout = () => {
    dispatch(setLogout());
    // Redirect or perform additional logout actions if necessary
  };

  return (
    <Navbar
      color="dark"
      dark
      expand="lg"
      className="navbar navbar-expand-lg sticky-top"
    >
      <div className="container-fluid d-flex align-items-start justify-content-between">
        <Link to="/" className="navbar-brand">
          <img
            src={dayaLogo}
            alt="logo"
            style={{
              height: "120px", // Adjust the logo height to avoid overlapping
              maxWidth: "150px", // Set a max width to control logo size
              objectFit: "contain",
            }}
            className="mr-3" // Add some margin to the right of the logo
          />
        </Link>

        <Collapse isOpen={isOpen} navbar id="navbarCollapse">
          <Nav className="ml-auto align-items-center" navbar>
            <NavItem className="mx-2">
              <NavLink
                tag={Link}
                to="/"
                className={location.pathname === "/" ? "active" : ""}
              >
                Home
              </NavLink>
            </NavItem>
            <NavItem className="mx-2">
              <NavLink
                tag={Link}
                to="/campaigns"
                className={location.pathname === "/campaigns" ? "active" : ""}
              >
                Campaigns
              </NavLink>
            </NavItem>
            <NavItem className="mx-2">
              <NavLink
                href="/#about"
                className={location.hash === "#about" ? "active" : ""}
              >
                About
              </NavLink>
            </NavItem>
            <NavItem className="mx-2">
              <NavLink
                href="/#service"
                className={location.hash === "#service" ? "active" : ""}
              >
                What We Do
              </NavLink>
            </NavItem>
            <NavItem className="mx-2">
              <NavLink
                href="/#our-app"
                className={location.hash === "#our-app" ? "active" : ""}
              >
                Our App
              </NavLink>
            </NavItem>
            <NavItem className="mx-2">
              <NavLink
                tag={Link}
                to="/contact"
                className={location.pathname === "/contact" ? "active" : ""}
              >
                Contact
              </NavLink>
            </NavItem>

            {user ? (
              <NavItem className="mx-2 ml-auto">
                <Dropdown isOpen={isDropdownOpen} toggle={toggleDropdown}>
                  <DropdownToggle
                    tag="span"
                    className="d-flex align-items-center"
                  >
                    <img
                      src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png" // Use default avatar if user has no avatar
                      alt="user-avatar"
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                      }}
                    />
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem header>{user.name}</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem onClick={handleLogout}>Logout</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </NavItem>
            ) : (
              <NavItem className="mx-2">
                <NavLink tag={Link} to="/login" style={{ color: "#fff" }}>
                  Login
                </NavLink>
              </NavItem>
            )}
            {/* Conditional rendering for login/logout */}
          </Nav>
        </Collapse>

        <NavbarToggler onClick={toggleNavbar} />
      </div>
    </Navbar>
  );
};

export default Header;
