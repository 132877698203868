import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import campaignService from "../../../service/campaignService";

export const initialState = {
  loading: false,
  campaigns: [],
  campaignDetail: {},
  banners: [],
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCampaigns.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCampaigns.fulfilled, (state, action) => {
        state.loading = false;
        state.campaigns = action.payload;
      })
      .addCase(getCampaigns.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(getCampaignDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCampaignDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.campaignDetail = action.payload;
      })
      .addCase(getCampaignDetails.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(getCampaignBanners.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCampaignBanners.fulfilled, (state, action) => {
        state.loading = false;
        state.banners = action.payload;
      })
      .addCase(getCampaignBanners.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const getCampaigns = createAsyncThunk(
  "campaign/getcampaigns",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const campaigns = await campaignService.getCampaigns();

      if (campaigns) {
        return campaigns;
      }
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const getCampaignDetails = createAsyncThunk(
  "campaign/getcampaigndetails",
  async (id, { dispatch, rejectWithValue }) => {
    try {
      const campaignDetail = await campaignService.getCampaignById(id);

      if (campaignDetail) {
        return campaignDetail;
      }
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const getCampaignBanners = createAsyncThunk(
  "campaign/getcampaignbanners",
  async (id, { dispatch, rejectWithValue }) => {
    try {
      const campaignBanners = await campaignService.getBanners();

      if (campaignBanners) {
        return campaignBanners;
      }
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const {} = authSlice.actions;

export default authSlice.reducer;
