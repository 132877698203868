import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "bootstrap/dist/css/bootstrap.min.css";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getCampaignDetails } from "../../GlobalRedux/Features/campaign/campaignSlice";
import Modal from "../../components/common/Modal";
import { Card, Col, Row, Button, Input, CardText } from "reactstrap";
import { toast } from "react-toastify";
import campaignService from "../../service/campaignService";
import DonationSuccessModal from "../../components/DonationSuccessModal";

// Slider settings for react-slick
const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
};

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

const CampaignDetail = () => {
  const { id } = useParams(); // Get campaign ID from URL
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [modalImage, setModalImage] = useState("");
  const [onDinationOpen, setDonationOpen] = useState(false);
  const [selectedAmount, setSelectedAmount] = useState("");
  const [showSuccessPayment, setShowSuccessPayment] = useState(false);
  const predefinedAmounts = [10, 50, 100, 150, 500, 1000];

  const toggleShowSuccessPayment = () =>
    setShowSuccessPayment(!showSuccessPayment);

  useEffect(() => {
    dispatch(getCampaignDetails(id));
  }, [id, dispatch]);

  const { campaignDetail, loading } = useSelector((state) => state.campaign);
  const { user } = useSelector((state) => state.auth);

  if (loading) return <p>Loading...</p>;
  if (!campaignDetail) return <p>No campaign data available.</p>;

  const calculateProgress = (total, needed) => {
    return ((total / needed) * 100).toFixed(2);
  };

  const formatCreatedAt = (date) => {
    return moment(date).fromNow();
  };

  const handleImageClick = (url) => {
    setModalImage(url);
    setShowModal(true);
  };

  const handleAmountClick = (amount) => {
    setSelectedAmount(amount);
  };

  const handleCustomAmountChange = (e) => {
    setSelectedAmount(e.target.value);
  };

  const handleSubmit = () => {
    console.log(user, "USERRR");

    console.log("Donated amount: ", selectedAmount);
  };

  async function displayRazorpay() {
    if (user) {
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );

      if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
      }

      const createdPaymentOrder = await campaignService.createPaymentOrder({
        campaign_id: id,
        amount: selectedAmount,
      });

      console.log(createdPaymentOrder, "created-payment");

      // creating a new order

      if (!createdPaymentOrder) {
        toast.error("Server error. Are you online?");
        return;
      }

      // Getting the order details back
      const { amount, order_id, currency } = createdPaymentOrder;

      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY, // Enter the Key ID generated from the Dashboard
        amount: amount.toString(),
        currency: currency,
        name: "Daya Life Line",
        description: "Contribute to the Campaign",
        //   image: { logo },
        order_id: order_id,
        handler: async function (response) {
          const data = {
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_order_id: response.razorpay_order_id,
            razorpay_signature: response.razorpay_signature,
          };

          const verifyPayment = await campaignService.verifyPaymentOrder(data);

          if (verifyPayment.message === "Payment successful") {
            toast.success("Payment was Done... Thank you for donation!");
            setShowSuccessPayment(true);
          } else {
            toast.error("Payment verification failed");
          }
        },
        prefill: {
          name: user?.name | "Donator",
          email: user?.email,
          contact: user?.phone_number,
        },
        //   notes: {
        //     address: "Soumya Dey Corporate Office",
        //   },
        //   theme: {
        //     color: "#61dafb",
        //   },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } else {
      toast.warn("Please Login Before Making donations");
      navigate("/login");
    }
  }

  return (
    <div className="container my-5">
      {campaignDetail?.id ? (
        <>
          <div className="mb-4">
            {(campaignDetail.image.length > 0 ||
              campaignDetail.youtube_video_url) && (
              <Slider {...sliderSettings}>
                {campaignDetail.image.map((img, index) => (
                  <div key={index}>
                    <img
                      src={img.url}
                      alt={`Campaign ${index + 1}`}
                      style={{
                        width: "100%",
                        height: "400px",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                ))}
                {campaignDetail.youtube_video_url && (
                  <div>
                    <iframe
                      width="100%"
                      height="400px"
                      src={campaignDetail.youtube_video_url.replace(
                        "watch?v=",
                        "embed/"
                      )}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                )}
              </Slider>
            )}
          </div>

          <div className="card shadow-sm">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center">
                {campaignDetail.is_emergency && (
                  <span className="badge bg-danger mb-3">Emergency</span>
                )}

                <div className="d-flex align-items-center mb-3">
                  <img
                    src={campaignDetail.sponsor_logo}
                    alt={campaignDetail.sponsor_name}
                    className="me-2"
                    style={{ height: "30px", objectFit: "contain" }}
                  />
                  <span>{campaignDetail.sponsor_name}</span>
                </div>
              </div>

              <h2 className="card-title mb-3">{campaignDetail.title}</h2>
              <div className="d-flex align-items-center mb-3">
                <img
                  src={campaignDetail.owner_photo}
                  alt={campaignDetail.owner_name}
                  className="rounded-circle me-2"
                  style={{ height: "50px", width: "50px", objectFit: "cover" }}
                />
                <span>{campaignDetail.owner_name}</span>
              </div>
              <p className="card-text">
                <strong>Location:</strong> {campaignDetail.location}
              </p>
              <p className="card-text">
                <strong>Amount Needed:</strong> ₹{campaignDetail.amount_needed}
              </p>
              <p className="card-text">
                <strong>Currently Raised:</strong> ₹
                {campaignDetail.total_amount}
              </p>
              <div className="progress mb-3">
                <div
                  className="progress-bar bg-success"
                  role="progressbar"
                  style={{
                    width: `${calculateProgress(
                      campaignDetail.total_amount,
                      campaignDetail.amount_needed
                    )}%`,
                  }}
                  aria-valuenow={campaignDetail.total_amount}
                  aria-valuemin="0"
                  aria-valuemax={campaignDetail.amount_needed}
                >
                  {calculateProgress(
                    campaignDetail.total_amount,
                    campaignDetail.amount_needed
                  )}
                  %
                </div>
              </div>

              <p className="card-text">
                <strong>Description:</strong> {campaignDetail.description}
              </p>

              <small className="text-muted">
                Created {formatCreatedAt(campaignDetail.created_at)}
              </small>

              {/* Forms Section */}
              {campaignDetail.forms && campaignDetail.forms.length > 0 && (
                <div className="mt-4">
                  <h4 className="mb-4">Forms</h4>
                  <div className="row">
                    {campaignDetail.forms.map((form, index) => (
                      <div className="col-md-4 mb-3" key={index}>
                        <img
                          src={form.url}
                          alt={form.text}
                          className="img-fluid"
                          style={{
                            cursor: "pointer",
                            objectFit: "cover",
                            height: 400,
                          }}
                          onClick={() => handleImageClick(form.url)}
                        />
                        <p className="mt-2">{form.text}</p>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>

            {/* Donation Section */}
            <Row>
              <Col sm="12">
                <Card body>
                  <h4>Make Donation Now</h4>
                  <CardText>
                    Every Donation Counts Please Stay Among Them
                  </CardText>
                  {/* Predefined Donation Amounts */}
                  <Row className="mb-3">
                    {predefinedAmounts.map((amount) => (
                      <Col key={amount} xs="6" md="4" className="mb-2">
                        <Button
                          color={
                            selectedAmount == amount
                              ? "primary"
                              : "outline-primary"
                          }
                          block
                          onClick={() => handleAmountClick(amount)}
                        >
                          ₹{amount}
                        </Button>
                      </Col>
                    ))}
                  </Row>

                  {/* Custom Donation Input */}
                  <Input
                    type="number"
                    className="form-control mb-3 py-3"
                    placeholder="Enter Custom Amount"
                    value={selectedAmount}
                    onChange={handleCustomAmountChange}
                  />

                  {/* Submit Button */}
                  <Button
                    color="success"
                    block
                    onClick={displayRazorpay}
                    disabled={!selectedAmount}
                    style={{ paddingTop: 15, paddingBottom: 15 }}
                  >
                    Donate ₹{selectedAmount || "Amount"}
                  </Button>
                </Card>
              </Col>
            </Row>
          </div>

          {/* Custom Modal for viewing larger image */}
          <Modal show={showModal} onClose={() => setShowModal(false)}>
            <span
              className="custom-modal-close"
              onClick={() => setShowModal(false)}
            >
              &times;
            </span>
            <img
              src={modalImage}
              alt="Form"
              style={{ width: "100%", height: "auto" }}
            />
          </Modal>

          <DonationSuccessModal
            isOpen={showSuccessPayment}
            toggle={toggleShowSuccessPayment}
            name={campaignDetail?.owner_name}
            amount={selectedAmount}
          />
        </>
      ) : (
        <>Not found</>
      )}
    </div>
  );
};

export default CampaignDetail;
