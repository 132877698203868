import React from "react";

const PrivacyPolicy = () => {
  return (
    <div class="container" style={{ paddingTop: 70 }}>
      <h2>1. Introduction</h2>
      <p>
        This Privacy Policy explains how <strong>DayaLifeline</strong> collects,
        uses, and protects your personal information.
      </p>

      <h2>2. Information We Collect</h2>
      <p>We collect the following types of information:</p>
      <ul>
        <li>
          <strong>Personal Information:</strong> Name, phone number, and bank
          account details of recipients.
        </li>
        <li>
          <strong>Usage Information:</strong> How you use our app, including
          interactions and transactions.
        </li>
      </ul>

      <h2>3. How We Use Your Information</h2>
      <p>We use your information for the following purposes:</p>
      <ul>
        <li>To provide and improve our services.</li>
        <li>To communicate with you about your account and transactions.</li>
        <li>To process donations and campaigns.</li>
      </ul>

      <h2>4. Sharing Your Information</h2>
      <p>
        We do not sell your personal information to third parties. We may share
        information with service providers to facilitate transactions and may
        disclose information to comply with legal obligations.
      </p>

      <h2>5. Data Security</h2>
      <p>
        We take reasonable measures to protect your personal information from
        unauthorized access, use, or disclosure.
      </p>

      <h2>6. Your Rights</h2>
      <p>
        You can access and update your personal information through your account
        settings. You can request the deletion of your account and personal
        information.
      </p>

      <h2>7. Cookies</h2>
      <p>
        Our app uses cookies to enhance user experience and analyze app usage.
        You can manage cookies through your browser settings.
      </p>

      <h2>8. Changes to Privacy Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. We will notify you
        of any changes through the app.
      </p>

      <h2>9. Contact Us</h2>
      <p>
        For questions or concerns about this Privacy Policy, please contact us
        at <a href="mailto:info@dayalifeline.com">info@dayalifeline.com</a>.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
